import MenuItem from "./MenuItem";
import { $ } from "../helpers";
import MenuAnimations from "./MenuAnimations";
import HeaderAnimations from "./HeaderAnimations";

class Menu {
  public readonly rootElement: HTMLElement;
  public readonly menuItems: MenuItem[] = [];
  public currentItem: MenuItem | null = null;
  public animations: HeaderAnimations;
  public animationHandlers: MenuAnimations;

  constructor(rootElement: HTMLElement | string) {
    this.rootElement = typeof rootElement === "string" ? $(rootElement) : rootElement;
    this.menuItems = this.populate();
    this.animations = new HeaderAnimations();
    this.animationHandlers = new MenuAnimations(this);
  }

  populate = (el?: HTMLLIElement): MenuItem[] => {
    const links: MenuItem[] = [];
    const element = el === undefined ? this.rootElement : el;
    const linksElements = element.querySelectorAll<HTMLLIElement>(`:scope > ul > .menu-item`);

    for (const link of [...linksElements]) {
      const menuItem = new MenuItem(link);
      const hasChildren = [...link.children].find((el) => el.tagName.toUpperCase() === "UL");
      hasChildren && (menuItem.children = this.populate(link as HTMLLIElement));
      menuItem.activate();

      menuItem.active && (this.currentItem = menuItem);
      links.push(menuItem);
    }

    return links;
  };

  update = () => {
    this.deactivateCurrent();
    this.activateCurrent();
  };

  deactivateCurrent = (passedList?: MenuItem[]) => {
    const list = passedList === undefined ? [...this] : passedList;
    const active = list.filter((item) => item.active || item.isActiveAncestor);

    for (const item of active) {
      item.deactivate();
      if (item.children.length > 0) this.deactivateCurrent(item.children);
    }

    this.currentItem = null;
  };

  activateCurrent = (passedList?: MenuItem[]) => {
    const list = passedList === undefined ? [...this] : passedList;

    for (const item of list) {
      if (item.children.length > 0) this.activateCurrent(item.children);
      item.activate();
      item.active && (this.currentItem = item);
    }
  };

  *[Symbol.iterator]() {
    for (const item of this.menuItems) {
      yield item;
    }
  }
}

export default Menu;
