import barba from "@barba/core";
import gsap, { Power2 } from "gsap";
import Menu from "./Menu/Menu";
import { $, mobileBreakpoint } from "./helpers";
import PageHandler from "../pages/PageHandler";
import MouseCircle from "./MouseCircle";
import StyleHandler from "./StyleHandler";
import Wpcf7CustomFile from "../plugins/Wpcf7CustomFile";
import TablePressHandler from "./TablePressHandler";

class Barba {
  private barba;
  private loader: HTMLElement;
  private shadow: HTMLElement;
  private pageHandler: PageHandler;
  private styleHandler: StyleHandler;

  constructor(public menu: Menu, public mouseCircle: MouseCircle) {
    this.barba = barba;
    this.pageHandler = new PageHandler();
    this.styleHandler = new StyleHandler();

    (this.loader = $(".loader")), (this.shadow = $(".loader__shadow"));

    this.barba.init({
      transitions: [this.defaultTransition()],
    });

    this.barba.hooks.once(() => {
      null;
    });

    this.barba.hooks.before(() => {
      this.menu.update();
    });

    this.barba.hooks.enter(() => {
      window.scrollTo(0, 0);
    });
  }

  //LOADER ANIMATIONS
  enterAnimation = () => {
    gsap //
      .timeline()
      .from(this.loader, { duration: 0, y: "100%" })
      .from(this.shadow, { duration: 0, y: "100%" })
      .to(this.shadow, { duration: 0.3, y: "0%", ease: Power2.easeInOut })
      .to(this.loader, { duration: 0.3, y: "0%", ease: Power2.easeInOut }, "<33%");
  };

  leaveAnimation = (cb?) => {
    setTimeout(() => {
      let played = false;
      const tl = gsap
        .timeline({
          onUpdate: () => {
            if (tl.progress() > 0.3 && !played) {
              if (cb) cb();
              played = true;
            }
          },
        })
        .from(this.loader, { duration: 0, y: "0%" })
        .from(this.shadow, { duration: 0, y: "0%" })
        .to(this.loader, { duration: 0.3, y: "-100%", ease: Power2.easeInOut })
        .to(this.shadow, { duration: 0.3, y: "-100%", ease: Power2.easeInOut }, "<33%")
        .set(this.loader, { y: "100%" })
        .set(this.shadow, { y: "100%" });
    }, 500);
  };

  //TRANSITIONS
  defaultTransition = () => {
    return {
      name: "default",
      once: () => {
        this.styleHandler.refresh();

        this.pageHandler.refresh();
        this.pageHandler
          .load()
          .then((instance) => instance.init())
          .then((cb) => {
            this.leaveAnimation(() => {
              this.menu.animationHandlers.enterAnimation();
              cb();

              new Wpcf7CustomFile();
            });
          });
      },
      leave: (data) => {
        this.enterAnimation();

        if (window.innerWidth < mobileBreakpoint) this.menu.animations.play("mobileNavigationClose");

        //delay content animation
        return gsap.to(data.current.container, {
          duration: 0,
          delay: 0.4,
        });
      },
      after: (data) => {
        //apply new classes to container
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(data.next.html.replace(/(<\/?)body( .+?)?>/gi, "$1notbody$2>"), "text/html");
        const bodyClasses = htmlDoc?.querySelector<HTMLElement>("notbody")?.getAttribute("class") as string;
        document.body.setAttribute("class", bodyClasses);

        const header = document.body.querySelector("header") as HTMLElement;
        document.body.classList.contains("home") ? header.setAttribute("data-frontpage", "") : header.removeAttribute("data-frontpage");

        this.menu.animations.play(document.body.classList.contains("home") ? "toHome" : "toPage");

        this.styleHandler.refresh();
        this.pageHandler.refresh();

        this.pageHandler
          .load()
          .then((instance) => instance.init())
          .then((cb) => {
            this.mouseCircle.refresh();
            this.leaveAnimation(() => {
              cb();

              (<any>window).lazy.update();
              (<any>window).a2a.init();

              new TablePressHandler();

              const forms = document.querySelectorAll(".wpcf7 > form");
              forms.forEach((form) => {
                (<any>window).wpcf7.init(form);
              });

              new Wpcf7CustomFile();
            });
          });
      },
    };
  };
}

export default Barba;
