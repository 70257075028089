import { $ } from "./helpers";

class Animations {
  public elements: AnimateElements;
  public timelines: Timelines;
  public root: HTMLElement;
  public started = false;

  constructor(root?) {
    if (root === undefined) {
      this.root = $("#main");
    } else this.root = root;

    this.elements = this.initializeElements();
    this.timelines = this.initTimelines();
  }

  public play = <T>(key: keyof Timelines, ...args: Array<T>): GSAPTimeline => this.timelines[key](...(args as Array<T>)).play();
  public init = <T>(key: keyof Timelines, ...args: Array<T>): GSAPTimeline => this.timelines[key](...(args as Array<T>));
  public initializeElements = (): AnimateElements => ({});
  public initTimelines = (): Timelines => ({});
}

export default Animations;
