import MenuItemAnimations from "./MenuItemAnimations";

const activeClasses: string[] = ["current-menu-item", "current_page_item"];
const ancestorClasses: string[] = ["current-menu-ancestor"];
const parentClasses: string[] = ["current-menu-parent"];

class MenuItem {
  public parent: HTMLLIElement | null = null;
  public link: HTMLAnchorElement;
  public children: MenuItem[] = [];
  public active = false;
  public isActiveParent = false;
  public isActiveAncestor = false;
  public animations: MenuItemAnimations;

  constructor(public readonly root: HTMLLIElement) {
    this.root = root;
    this.link = this.root.querySelector("a") as HTMLAnchorElement;
    this.animations = new MenuItemAnimations(this);
  }

  activate = (): void => {
    this.setActive();

    for (const item of this.children) {
      if (item.active) {
        this.setActiveParent();
        this.setActiveAncestor();
      } else if (item.isActiveAncestor) {
        this.setActiveAncestor();
      }
    }
  };

  deactivate = (): void => {
    if (this.active) {
      this.active = false;
      this.root.classList.remove(...activeClasses);
      this.animations.onDeactivate();
    }

    if (this.isActiveParent) {
      this.isActiveParent = false;
      this.root.classList.remove(...parentClasses);
    }

    if (this.isActiveAncestor) {
      this.isActiveAncestor = false;
      this.root.classList.remove(...ancestorClasses);
    }
  };

  setActive = (): void => {
    if (window.location.href === this.link.href) {
      this.active = true;
      this.root.classList.add(...activeClasses);
    }
  };

  setActiveParent = (): void => {
    this.isActiveParent = true;
    this.root.classList.add(...parentClasses);
  };

  setActiveAncestor = (): void => {
    this.isActiveAncestor = true;
    this.root.classList.add(...ancestorClasses);
  };
}

export default MenuItem;
