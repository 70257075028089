import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Animations from "../Animations";

gsap.registerPlugin(ScrollTrigger);

class SectionAnimations extends Animations implements AnimationClass {
  public elements: AnimateElements;
  public timelines: Timelines;

  constructor(root?: HTMLElement) {
    super(root);
    this.elements = this.initializeElements();
    this.timelines = this.initTimelines();
  }

  //animatable elements
  initializeElements = (): AnimateElements => ({
    root: this.root as HTMLElement,
    container: this.root.querySelector(".container") as HTMLElement,
    mainCol: this.root.querySelector(".main-col") as HTMLElement,
    nav: this.root.querySelector(".nav") as HTMLElement,
    socials: this.root.querySelector(".socials") as HTMLElement,
    gear: this.root.querySelector(".gear") as HTMLElement,
    webtom: this.root.querySelector(".webtom") as HTMLElement,
    mobileMenu: this.root.querySelector(".mobile-menu") as HTMLElement,
    copyrights: this.root.querySelectorAll<HTMLElement>(".copyright"),
  });

  initTimelines = (): Timelines => {
    return {
      itemsEnter: () => {
        return gsap //
          .timeline({ scrollTrigger: { trigger: this.elements.mainCol, start: "70% bottom" } })
          .from((this.elements.mainCol as HTMLElement).children, { y: -10, opacity: 0 })
          .to((this.elements.mainCol as HTMLElement).children, { y: 0, opacity: 1 })

          .from(this.elements.copyrights, { y: -10, opacity: 0 }, 0)
          .to(this.elements.copyrights, { y: 0, opacity: 1 }, 0)

          .from((this.elements.nav as HTMLElement).children, { y: -10, opacity: 0 }, 0.2)
          .to((this.elements.nav as HTMLElement).children, { y: 0, opacity: 1 }, 0.2)

          .from((this.elements.socials as HTMLElement).children, { y: -10, opacity: 0 }, 0.4)
          .to((this.elements.socials as HTMLElement).children, { y: 0, opacity: 1 }, 0.4)

          .from(this.elements.webtom, { opacity: 0 }, 1)
          .to(this.elements.webtom, { opacity: 1 }, 1);
      },
      gearsAnimation: () =>
        gsap //
          .timeline({ scrollTrigger: { trigger: this.elements.gear, start: "top bottom", scrub: true } })
          .to(this.elements.gear, { rotate: 30 }, 0),
    };
  };

  public start = () => {
    this.init("itemsEnter");
    this.init("gearsAnimation");
  };
}

export default SectionAnimations;
