import Menu from "./Menu";
import gsap from "gsap";
import { $, mobileBreakpoint } from "../helpers";
class MenuAnimations {
  constructor(private readonly menu: Menu) {
    this.dropdownsAnimation();
    this.onScrollAnimation();
    this.onResizeAnimation();
    this.mobileMenuAnimation();
  }

  enterAnimation = () => {
    const {
      menu: { animations },
    } = this;

    const enterAnimation = gsap.timeline();
    document.body.classList.contains("home") && enterAnimation.add(animations.play("showBg"));
    window.innerWidth > mobileBreakpoint //
      ? enterAnimation.add(animations.play("itemsAnimation"), "<")
      : enterAnimation.add(animations.play("logoAndHamburgerEnter"), "<");
  };

  dropdownsAnimation = () => {
    const {
      menu: { animations },
    } = this;

    const itemsWithChildren = [...this.menu].filter((item) => item.root.classList.contains("has-children"));
    for (const item of itemsWithChildren) {
      item.root.addEventListener("mouseenter", () => {
        window.innerWidth > mobileBreakpoint //
          ? animations.play<HTMLElement>("showDropdown", item.root.querySelector(":scope ul") as HTMLElement)
          : null;
      });
      item.root.addEventListener("mouseleave", () => {
        window.innerWidth > mobileBreakpoint //
          ? animations.play<HTMLElement>("hideDropdown", item.root.querySelector(":scope ul") as HTMLElement)
          : null;
      });
      const mobileToggler = item.root.querySelector(":scope .mobile-toggler") as HTMLElement;
      mobileToggler?.addEventListener("click", () => {
        mobileToggler.classList.contains("open") //
          ? animations.play<HTMLElement>("hideMobileDropdown", mobileToggler, item.root.querySelector(":scope ul") as HTMLElement)
          : animations.play<HTMLElement>("showMobileDropdown", mobileToggler, item.root.querySelector(":scope ul") as HTMLElement);
      });
    }
  };

  onScrollAnimation = () => {
    const header = $("#header") as HTMLElement;
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        !header.classList.contains("scrolled-down") && header.classList.add("scrolled-down");
      } else {
        header.classList.contains("scrolled-down") && header.classList.remove("scrolled-down");
      }
    });
  };

  onResizeAnimation = () => {
    const {
      menu: { animations },
    } = this;

    let state = innerWidth < mobileBreakpoint ? "pc" : "mobile";

    window.addEventListener("resize", () => {
      document.body.classList.contains("home") && animations.play("resizeBg");

      if (innerWidth >= mobileBreakpoint && state === "mobile") {
        animations.play("fromMobile");
      } else if (innerWidth <= mobileBreakpoint && state === "pc") {
        animations.play("fromPc");
      }

      const newState = innerWidth > mobileBreakpoint ? "pc" : "mobile";
      if (newState !== state) state = newState;
    });
  };

  mobileMenuAnimation = () => {
    const {
      menu: { animations },
    } = this;

    const header = $("#header") as HTMLElement;
    const hamburgetBtn = header.querySelector(".hamburger") as HTMLButtonElement;

    hamburgetBtn.addEventListener("click", () => {
      if (hamburgetBtn.classList.contains("animating")) return;
      hamburgetBtn.classList.contains("open") ? animations.play("mobileNavigationClose") : animations.play("mobileNavigationOpen");
    });
  };
}

export default MenuAnimations;
