import gsap, { Power1, Power3, Power2 } from "gsap";
import { $, disableScroll, enableScroll, mobileBreakpoint, getHiddenElementHeight } from "../helpers";
class HeaderAnimations implements AnimationClass {
  public elements: AnimateElements;
  public timelines: Timelines;
  public root: HTMLElement;
  public started = false;

  public customAttributes: Attributes = {
    headerBgHeights: {
      1280: 400,
      1024: 300,
      0: 150,
    },
    colors: {
      dark: "#575755",
      black: "#111827",
      light: "#f5f5f5",
    },
  };

  public customMethods = {
    getBgHeightValues: () =>
      this.customAttributes.headerBgHeights[
        Object.keys(this.customAttributes.headerBgHeights)
          .sort((a, b) => Number.parseInt(b) - Number.parseInt(a))
          .find((key) => Number.parseInt(key) < window.innerWidth) || 0
      ],
  };

  constructor() {
    this.root = $("#header");

    this.elements = this.initializeElements();
    this.timelines = this.initTimelines();
  }

  //animatable elements
  initializeElements = () => ({
    header: this.root as HTMLElement,
    navbarItems: this.root.querySelectorAll<HTMLLIElement>(".header-navigation > .menu-item"),
    navbarLinks: this.root.querySelectorAll<HTMLAnchorElement>(".header-navigation > .menu-item > a"),
    navabarLogoLetters: this.root.querySelectorAll<HTMLElement>(".is-logo svg .logo-text"),
    headerBackground: this.root.querySelector(".header-bg") as HTMLElement,
    mobileLogo: this.root.querySelector(".header-logo svg") as HTMLElement,
    mobileLogoLetters: this.root.querySelectorAll<HTMLElement>(".header-logo svg .logo-text"),
    hamburgerBtn: this.root.querySelector(".hamburger") as HTMLElement,
    mainNavigation: this.root.querySelector("#main-navigation") as HTMLElement,
    navShadow: this.root.querySelector(".nav-shadow") as HTMLElement,
  });

  public play = <T>(key: keyof Timelines, ...args: Array<T>): GSAPTimeline => this.timelines[key](...(args as Array<T>)).play();

  initTimelines = (): Timelines => {
    return {
      itemsAnimation: () =>
        gsap // showind menu items
          .timeline({ paused: true })
          .from(this.elements.navbarItems, { y: -20, opacity: 0, stagger: 0.1 })
          .to(this.elements.navbarItems, { y: 0, opacity: 1, ease: Power3.easeIn }),
      //
      hideBg: () =>
        gsap // hiding headers gradient background
          .timeline({ paused: true })
          .from(this.elements.headerBackground, { height: this.customMethods.getBgHeightValues() })
          .to(this.elements.headerBackground, { height: 0, duration: 0.2, ease: Power3.easeIn }),
      //
      showBg: () =>
        gsap // showing headers gradient background
          .timeline({ paused: true })
          .from(this.elements.headerBackground, { height: 0 })
          .to(this.elements.headerBackground, { height: this.customMethods.getBgHeightValues(), duration: 0.3, delay: 0.3, ease: Power3.easeOut }),
      //
      resizeBg: () =>
        gsap // showing headers gradient background
          .timeline({ paused: true })
          .to(this.elements.headerBackground, { height: this.customMethods.getBgHeightValues(), duration: 0.3, ease: Power3.easeOut }),
      //
      setItemsWhite: () =>
        gsap //set navigation elements white
          .timeline({ paused: true })
          .to(this.elements.navbarLinks, { color: this.customAttributes.colors.light, duration: 0.3 }),
      //
      setItemsBlack: () =>
        gsap //set navigation elements black
          .timeline({ paused: true })
          .to(this.elements.navbarLinks, { color: this.customAttributes.colors.dark, duration: 0.3 }),
      //
      setNavLogoBlack: () =>
        gsap // set logo letters black
          .timeline({ paused: true })
          .to(this.elements.navabarLogoLetters, { fill: this.customAttributes.colors.black, duration: 0.2 }),
      //
      setLogoBlack: () =>
        gsap // set mobile logo letters black
          .timeline({ paused: true })
          .to([...(this.elements.mobileLogoLetters as Array<HTMLElement>)].reverse(), { fill: this.customAttributes.colors.black, duration: 0.2, delay: 0.6 }),
      //
      setNavLogoWhite: () =>
        gsap // set mobile logo letters white
          .timeline({ paused: true })
          .to(this.elements.navabarLogoLetters, { fill: this.customAttributes.colors.light, duration: 0.2 }),
      //
      setLogoWhite: () =>
        gsap // set mobile logo letters black
          .timeline({ paused: true })
          .to([...(this.elements.mobileLogoLetters as Array<HTMLElement>)].reverse(), { fill: this.customAttributes.colors.light, duration: 0.2, delay: 0.6 }),
      //
      setHamburgerBlack: () => {
        const [stBar, ndBar, rdBar] = [...(this.elements.hamburgerBtn as HTMLButtonElement).querySelectorAll("span")];
        return gsap //
          .timeline({ paused: true })
          .to([stBar, ndBar, rdBar], { background: this.customAttributes.colors.black, duration: 0.3, ease: Power2.easeInOut }, "<");
      },
      setHamburgerWhite: () => {
        const [stBar, ndBar, rdBar] = [...(this.elements.hamburgerBtn as HTMLButtonElement).querySelectorAll("span")];
        return gsap //
          .timeline({ paused: true })
          .to([stBar, ndBar, rdBar], { background: this.customAttributes.colors.light, duration: 0.3, ease: Power2.easeInOut }, "<");
      },
      toPage() {
        return gsap //change page to normal page
          .timeline({ paused: true })
          .add(window.innerWidth > mobileBreakpoint ? this.setItemsBlack().play() : gsap.timeline(), "<")
          .add(this.setHamburgerBlack().play(), "<")
          .add(this.hideBg().play(), "<")
          .add(this.setNavLogoBlack().play(), "<")
          .add(this.setLogoBlack().play(), "<");
      },
      //
      toHome() {
        return gsap //change page to homepage
          .timeline({ paused: true })
          .add(window.innerWidth > mobileBreakpoint ? this.setItemsWhite().play() : gsap.timeline(), "<")
          .add(this.setHamburgerWhite().play(), "<")
          .add(this.showBg().play(), "<")
          .add(this.setNavLogoWhite().play(), "<")
          .add(this.setLogoWhite().play(), "<");
      },
      //
      showDropdown: (dropdown) => {
        const items = dropdown?.querySelectorAll(":scope li") || [];
        return gsap //
          .timeline({ paused: true })
          .to(dropdown, { opacity: 1, display: "block", duration: 0.3, ease: Power1.easeIn })
          .to(items, { y: 0, opacity: 1, duration: 0.3, stagger: 0.04, ease: Power1.easeIn }, "<")
          .to(dropdown, { boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", duration: 0.3, ease: Power1.easeIn }, "<25%");
      },
      //
      hideDropdown: (dropdown) => {
        const items = dropdown?.querySelectorAll(":scope li") || [];
        return gsap //
          .timeline({ paused: true })
          .to(dropdown, { boxShadow: "rgba(0, 0, 0, 0) 0px 10px 15px -3px, rgba(0, 0, 0, 0) 0px 4px 6px -2px", duration: 0.3, ease: Power3.easeOut })
          .to(dropdown, { opacity: 0, duration: 0.3, display: "none", ease: Power3.easeOut }, "<75%")
          .to(items, { y: -10, opacity: 0, duration: 0.2, stagger: 0.04, ease: Power3.easeOut }, "<");
      },
      //
      logoAndHamburgerEnter: () =>
        gsap //
          .timeline({ paused: true })
          .from(this.elements.mobileLogo, { y: -20, opacity: 0, delay: 0.5 })
          .from(this.elements.hamburgerBtn, { y: -20, opacity: 0 })
          .to(this.elements.mobileLogo, { y: 0, opacity: 1 })
          .to(this.elements.hamburgerBtn, { y: 0, opacity: 1 }),
      //
      mobileNavigationOpen: () => {
        const itemsWithoutLogo = [...(this.elements.navbarItems as NodeListOf<HTMLElement>)].filter((item) => !item.classList.contains("is-logo"));
        const gear = (this.elements.mainNavigation as HTMLElement).querySelector(":scope .gear");
        const logoLetters = [...(this.elements.mobileLogoLetters as Array<HTMLElement>)].reverse();
        const [stBar, ndBar, rdBar] = [...(this.elements.hamburgerBtn as HTMLButtonElement).querySelectorAll("span")];

        return gsap //
          .timeline({
            paused: true,
            onStart: () => {
              (this.elements.hamburgerBtn as HTMLElement).classList.add("animating", "open");
              disableScroll();
            },
            onComplete: () => (this.elements.hamburgerBtn as HTMLElement).classList.remove("animating"),
          })
          .to(this.elements.navShadow, { right: 0, duration: 0.6, ease: Power2.easeInOut })
          .to(logoLetters, { fill: this.customAttributes.colors.black, duration: 0.6, stagger: 0.07, ease: Power2.easeInOut }, "<")
          .to(this.elements.hamburgerBtn, { rotate: 360, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to([stBar, ndBar, rdBar], { background: this.customAttributes.colors.black, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to(stBar, { rotate: -45, y: 11, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to(rdBar, { rotate: 45, y: -11, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to([ndBar], { opacity: 0, y: -10, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to(this.elements.mainNavigation, { right: 0, duration: 0.6, ease: Power2.easeInOut }, "<33%")
          .to(gear, { rotate: -60, duration: 1, ease: Power2.easeInOut }, "<")
          .to(itemsWithoutLogo, { x: 0, opacity: 1, duration: 0.5, stagger: 0.07, ease: Power2.easeInOut }, "<");
      },
      mobileNavigationClose: () => {
        const itemsWithoutLogo = [...(this.elements.navbarItems as NodeListOf<HTMLElement>)].filter((item) => !item.classList.contains("is-logo"));
        const gear = (this.elements.mainNavigation as HTMLElement).querySelector(":scope .gear");
        const logoLetters = [...(this.elements.mobileLogoLetters as Array<HTMLElement>)].reverse();
        const [stBar, ndBar, rdBar] = [...(this.elements.hamburgerBtn as HTMLButtonElement).querySelectorAll("span")];

        return gsap //
          .timeline({
            paused: true,
            onStart: () => (this.elements.hamburgerBtn as HTMLElement).classList.add("animating"),
            onComplete: () => {
              (this.elements.hamburgerBtn as HTMLElement).classList.remove("animating", "open");
              enableScroll();
            },
          })
          .to(itemsWithoutLogo, { x: -50, opacity: 0, duration: 0.5, stagger: 0.07, ease: Power2.easeInOut })
          .to(this.elements.mainNavigation, { right: "100%", ease: Power2.easeInOut }, "<15%")
          .to(logoLetters, { fill: this.customAttributes.colors[document.body.classList.contains("home") ? "light" : "black"], duration: 0.5, stagger: 0.07, ease: Power2.easeInOut }, "<")
          .to(gear, { rotate: -120, duration: 0.5, ease: Power2.easeInOut }, "<")
          .to(this.elements.hamburgerBtn, { rotate: 720, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to(stBar, { rotate: 0, y: 0, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to(rdBar, { rotate: 0, y: -0, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to([ndBar], { opacity: 1, y: 0, duration: 0.3, ease: Power2.easeInOut }, "<")
          .to([stBar, ndBar, rdBar], { background: this.customAttributes.colors[document.body.classList.contains("home") ? "light" : "black"], duration: 0.3, ease: Power2.easeInOut }, "<25%")
          .to(this.elements.navShadow, { right: "100%", duration: 0.5, ease: Power2.easeInOut }, "<33%")
          .set(this.elements.hamburgerBtn, { rotate: 0 })
          .set(itemsWithoutLogo, { x: 50 })
          .set(this.elements.mainNavigation, { right: "-100%" })
          .set(gear, { rotate: 0 })
          .set(this.elements.navShadow, { right: "-100%" });
      },
      showMobileDropdown: (togglerBtn, dropdown) => {
        const items = dropdown?.querySelectorAll(":scope li") || [];
        const height = getHiddenElementHeight(dropdown);

        return gsap //
          .timeline({
            paused: true,
            onStart: () => {
              togglerBtn.classList.add("open");
              dropdown.classList.add("open");
            },
          })
          .to(togglerBtn, { rotate: 180, duration: 0.3, ease: Power2.easeInOut })
          .to(dropdown, { display: "block", opacity: 1, height: `${height}px`, duration: 0.5, ease: Power2.easeInOut }, "<25%")
          .to(items, { y: 0, opacity: 1, duration: 0.3, stagger: 0.04, ease: Power1.easeIn }, "<50%");
      },
      hideMobileDropdown: (togglerBtn, dropdown) => {
        const items = dropdown?.querySelectorAll(":scope li") || [];

        return gsap //
          .timeline({
            paused: true,
            onComplete: () => {
              togglerBtn.classList.remove("open");
              dropdown.classList.remove("open");
            },
          })
          .to([...items].reverse(), { y: 10, opacity: 0, duration: 0.3, stagger: 0.04, ease: Power1.easeIn })
          .to(dropdown, { display: "none", opacity: 0, height: 0, duration: 0.5, ease: Power2.easeInOut }, "<50%")
          .to(togglerBtn, { rotate: 0, duration: 0.3, ease: Power2.easeInOut }, "<25%");
      },
      fromMobile: () => {
        const itemsWithoutLogo = [...(this.elements.navbarItems as NodeListOf<HTMLElement>)].filter((item) => !item.classList.contains("is-logo"));
        const dropdowns = document.body.querySelectorAll(".menu-item-children");

        dropdowns.forEach((dropdown) => dropdown.removeAttribute("style"));

        return gsap //
          .timeline({ paused: true })
          .set(this.elements.navbarLinks, { color: this.customAttributes.colors[document.body.classList.contains("home") ? "light" : "black"] })
          .set(itemsWithoutLogo, { x: 0, opacity: 1 })
          .set(this.elements.mainNavigation, { right: 0 });
      },
      fromPc: () => {
        const itemsWithoutLogo = [...(this.elements.navbarItems as NodeListOf<HTMLElement>)].filter((item) => !item.classList.contains("is-logo"));
        const dropdown = document.body.querySelector(".menu-item-children.open");

        const height = dropdown ? getHiddenElementHeight(dropdown) : 0;

        if ((this.elements.hamburgerBtn as HTMLButtonElement).classList.contains("open")) {
          return gsap //
            .timeline({ paused: true })
            .set(dropdown === null ? [] : dropdown, { height: height, opacity: 1 })
            .set(dropdown?.querySelectorAll("li") === undefined ? [] : dropdown?.querySelectorAll("li"), { x: 0, opacity: 1, boxShadow: "rgba(0, 0, 0, 0) 0px 10px 15px -3px, rgba(0, 0, 0, 0) 0px 4px 6px -2px" })
            .set(itemsWithoutLogo, { x: 0, opacity: 1 })
            .set(this.elements.navbarLinks, { color: this.customAttributes.colors.black })
            .set(this.elements.mainNavigation, { right: 0 });
        } else {
          return gsap //
            .timeline({ paused: true })
            .set(dropdown === null ? [] : dropdown, { height: height, opacity: 1 })
            .set(dropdown?.querySelectorAll("li") === undefined ? [] : dropdown?.querySelectorAll("li"), { x: 0, opacity: 1, boxShadow: "rgba(0, 0, 0, 0) 0px 10px 15px -3px, rgba(0, 0, 0, 0) 0px 4px 6px -2px" })
            .set(itemsWithoutLogo, { x: 50, opacity: 0 })
            .set(this.elements.navbarLinks, { color: this.customAttributes.colors.black })
            .set(this.elements.mainNavigation, { right: "-100%" });
        }
      },
    };
  };

  start = () => {
    return;
  };

  init = () => {
    return gsap.timeline();
  };
}

export default HeaderAnimations;
