import MenuItem from "./MenuItem";
import gsap, { Power2 } from "gsap";

class MenuItemAnimations {
  private mousePosX = 0;
  private mousePosY = 0;
  private rect: DOMRect | null = null;

  constructor(private item: MenuItem) {
    this.startWatching();
    this.onActivate();
  }

  startWatching = () => {
    document.addEventListener("mousemove", (e) => {
      this.mousePosX = e.clientX;
      this.mousePosY = e.clientY;
    });

    this.rect = this.item.link.getBoundingClientRect() as DOMRect;

    let revisedY = this.rect.top,
      revisedX = this.rect.left;

    const delay = 6;

    const pathFollow = () => {
      requestAnimationFrame(pathFollow);

      /*Adjust the clip-path*/
      if (this.rect === null) return;
      if (this.item.active) return;

      const currentRect = this.item.link.getBoundingClientRect();
      if (this.rect.left !== currentRect.left) this.rect = currentRect;
      if (this.rect.top !== currentRect.top) this.rect = currentRect;

      const xPos = this.mousePosX - this.rect.left;
      if (xPos < -150 || xPos > this.item.link.offsetWidth + 150) return;

      const yPos = this.mousePosY - this.rect.top;
      if (yPos < -150 || yPos > this.item.link.offsetHeight + 150) return;

      revisedX += (xPos - revisedX) / delay;
      revisedY += (yPos - revisedY) / delay;

      this.item.link.style.setProperty("--x", revisedX + "px");
      this.item.link.style.setProperty("--y", revisedY + "px");
    };

    pathFollow();
  };

  resetPosition = () => {
    this.item.link.getBoundingClientRect();
  };

  onActivate = () => {
    this.item.link.addEventListener("click", (e) => {
      if (this.rect === null) return;
      this.item.link.style.setProperty("--x", e.clientX - this.rect.left + "px");
      this.item.link.style.setProperty("--y", e.clientY - this.rect.top + "px");
      gsap //
        .timeline()
        .to(this.item.link, { "--circleSize": "200px", duration: 0.3, ease: Power2.easeIn })
        .set(this.item.link, { "--circleSize": "12px" });
    });
  };

  onDeactivate = () => {
    gsap //
      .timeline()
      .to(this.item.link, { "--circleSize": "0px", duration: 0.5, ease: Power2.easeOut })
      .set(this.item.link, { "--y": "-50px" })
      .set(this.item.link, { "--x": "-50px" })
      .set(this.item.link, { "--circleSize": "12px" });
  };
}

export default MenuItemAnimations;
