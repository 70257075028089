class PageHandler {
  private routes: Routes;
  private currentRoute: string;
  private classes: string;

  constructor() {
    this.routes = this.initRoutes();
    this.classes = this.setClasses();
    this.currentRoute = this.setCurrentRoute();
  }

  initRoutes = () => {
    const scripts = ["Frontpage", "Page", "Blog"];
    return {
      home: {
        script: scripts.at(0) as string,
      },
      page: {
        script: scripts.at(1) as string,
      },
      blog: {
        script: scripts.at(2) as string,
      },
    };
  };

  setClasses = () => [...document.body.classList].join(" ");

  setCurrentRoute = () => {
    if (this.classes.includes("home")) {
      return "home";
    }

    if (this.classes.includes("blog") || this.classes.includes("category")) {
      return "blog";
    }

    return "page";
  };

  load = async () => {
    const { script, init } = this.routes[this.currentRoute];

    const { default: LoadedClass } = await import(/* webpackChunkName: "[request]" */ `./${script}.ts`);
    const instance = new LoadedClass();

    if (typeof init === "function") init(instance);

    return new Promise<PageInterface>((resolve) => {
      resolve(instance as PageInterface);
    });
  };

  refresh = () => {
    this.classes = this.setClasses();
    this.currentRoute = this.setCurrentRoute();
  };
}

export default PageHandler;
