import Menu from "./components/Menu/Menu";
import Barba from "./components/Barba";
import MouseCircle from "./components/MouseCircle";
import LazyLoadImages from "./components/LazyLoadImages";
import Footer from "./components/Footer";
import Cookies from "./components/Cookies";

window.addEventListener("DOMContentLoaded", () => {
  const menu = new Menu("#main-navigation");
  new Footer(document.querySelector("#footer") as HTMLElement);
  const mouseCircle = new MouseCircle();
  new Cookies().init();

  (<any>window).lazy = new LazyLoadImages();

  const wpAdminBarLinks = document.querySelectorAll("#wpadminbar a");
  if (wpAdminBarLinks.length > 0) {
    wpAdminBarLinks.forEach((item) => item.setAttribute("data-barba-prevent", "self"));
  }

  new Barba(menu, mouseCircle);
});

window.addEventListener("resize", () => {
  (<any>window).lazy.update();
});
