class StyleHandler {
  private routes: any;
  private currentRoute: string;
  private files: string;

  constructor() {
    this.routes = this.initRoutes();
    this.files = this.setFiles();
    this.currentRoute = this.setCurrentRoute();
  }

  initRoutes = () => {
    const styles = ["frontpage.scss", "page.scss", "single-career.scss", "blog.scss", "single-post.scss"];
    return {
      home: {
        file: styles.at(0) as string,
      },
      page: {
        file: styles.at(1) as string,
      },
      "single-career": {
        file: styles.at(2) as string,
      },
      blog: {
        file: styles.at(3) as string,
      },
      category: {
        file: styles.at(3) as string,
      },
      "single-post": {
        file: styles.at(4) as string,
      },
    };
  };

  setFiles = () => [...document.body.classList].join(" ");

  setCurrentRoute = () => {
    return (
      Object.keys(this.routes).find((key) => {
        return document.body.classList.contains(key);
      }) || "page"
    );
  };

  load = async () => {
    const { file } = this.routes[this.currentRoute];

    await import(/* webpackChunkName: "[request]" */ `../../scss/${file}`);

    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  refresh = () => {
    this.files = this.setFiles();
    this.currentRoute = this.setCurrentRoute();
    this.load();
  };
}

export default StyleHandler;
