import gsap, { Power2 } from "gsap";
interface MouseCircleProps {
  delay?: number;
  id?: string;
  hideBreakpoint?: number;
}

const defaults: MouseCircleProps = {
  delay: 6,
  id: "mouse-circle",
  hideBreakpoint: 768,
};

class MouseCircle {
  private properties: MouseCircleProps = {};
  private mousePosX = 0;
  private mousePosY = 0;
  private mouseFixedPosX = 0;
  private mouseFixedPosY = 0;
  private rootElement: HTMLElement;

  constructor(props?: MouseCircleProps) {
    this.properties = { ...defaults, ...props };
    this.rootElement = this.createCircle();
    this.mount();
    this.getHandledElements();

    this.startWatching();
  }

  createCircle = () => {
    const circle: HTMLElement = document.createElement("div");
    circle.id = this.properties.id as string;
    document.body.appendChild(circle);
    return circle;
  };

  mount = () => {
    this.addOnMouseMoveEvent();

    let revisedMousePosX = 0,
      revisedMousePosY = 0;

    const delayMouseFollow = () => {
      requestAnimationFrame(delayMouseFollow);

      if (!this.properties.delay) return;

      revisedMousePosX += (this.mouseFixedPosX - revisedMousePosX) / this.properties.delay;
      revisedMousePosY += (this.mouseFixedPosY - revisedMousePosY) / this.properties.delay;

      this.rootElement.style.top = revisedMousePosY + "px";
      this.rootElement.style.left = revisedMousePosX + "px";
    };

    delayMouseFollow();
  };

  addOnMouseMoveEvent = () => {
    document.onmousemove = (e) => {
      if (!this.rootElement.classList.contains("moved")) this.rootElement.classList.add("moved");
      this.mousePosX = e.clientX;
      this.mousePosY = e.clientY;

      this.mouseFixedPosX = e.pageX;
      this.mouseFixedPosY = e.pageY;
    };
  };

  getHandledElements = () => {
    const elements = document.querySelectorAll(`[data-circle]:not([data-outline])`);
    elements.forEach((el) => {
      el.addEventListener("mouseenter", () => {
        this.rootElement.classList.add("show");
      });
      el.addEventListener("mouseleave", () => {
        this.rootElement.classList.remove("show");
      });
    });
  };

  startWatching = () => {
    const animatedButtons = document.querySelectorAll("#main a[data-circle], #main button[data-circle]:not([disabled]), .cookies button[data-circle]");

    animatedButtons.forEach((btn) => {
      let rect = btn.getBoundingClientRect() as DOMRect;

      let revisedY = rect.top,
        revisedX = rect.left;

      const delay = 6;

      btn.addEventListener("click", () => {
        (btn as HTMLElement).style.setProperty("--x", revisedX + "px");
        (btn as HTMLElement).style.setProperty("--y", revisedY + "px");
        gsap //
          .timeline()
          .to(btn, { "--circleSize": "300px", duration: 0.2, ease: Power2.easeIn })
          .to(btn, { "--circleSize": "32px", delay: 0.5, duration: 0.2, ease: Power2.easeOut });
      });

      const pathFollow = () => {
        requestAnimationFrame(pathFollow);

        /*Adjust the clip-path*/
        if (rect === null) return;

        const currentRect = btn.getBoundingClientRect();

        if (rect.left !== currentRect.x) rect = currentRect;
        if (rect.top !== currentRect.y) rect = currentRect;

        const xPos = this.mousePosX - rect.left;
        if (xPos < -300 || xPos > (btn as HTMLElement).offsetWidth + 300) return;

        const yPos = this.mousePosY - rect.top;
        if (yPos < -300 || yPos > (btn as HTMLElement).offsetHeight + 300) return;

        revisedX += (xPos - revisedX) / delay;
        revisedY += (yPos - revisedY) / delay;

        (btn as HTMLElement).style.setProperty("--x", revisedX + "px");
        (btn as HTMLElement).style.setProperty("--y", revisedY + "px");
      };

      pathFollow();
    });

    return new Promise((resolve) => {
      resolve(true);
    });
  };

  refresh = () => this.startWatching();
}

export default MouseCircle;
