import { cookieGet, cookieSet } from "./helpers";

class Cookies {
  root: HTMLElement;
  constructor() {
    this.root = document.querySelector(".cookies") as HTMLElement;

    if (!this.root) return;

    if (cookieGet("allow-cookies") === null) this.root.classList.remove("-translate-x-full");
  }

  init = () => {
    if (!this.root) return;
    const btn = this.root.querySelector("#close");
    btn?.addEventListener("click", (e) => {
      this.root.remove();
      cookieSet("allow-cookies", JSON.stringify(true), "/", 99);
    });
  };
}

export default Cookies;
