export const $ = (selector: string): HTMLElement => {
  return document.querySelector(selector) as HTMLElement;
};

export const mobileBreakpoint = 1024;

export const disableScroll = () => {
  document.body.style.overflow = "hidden";
  const x = window.scrollX;
  const y = window.scrollY;
  window.scrollTo(x, y);
  window.onscroll = function () {
    window.scrollTo(x, y);
  };
};

export const enableScroll = () => {
  window.onscroll = function () {
    return null;
  };
  document.body.style.overflow = "";
};

export const getHiddenElementHeight = (element, classToAdd?) => {
  const clone = element.cloneNode(true);
  const data = element.getBoundingClientRect();
  clone.style.position = "absolute";
  clone.style.display = "block";
  clone.style.height = "auto";
  clone.style.width = data.width + "px";
  clone.classList.add(classToAdd === undefined ? "open" : classToAdd);
  clone.style.left = "-100000em";
  clone.id = "tmp-" + Math.floor(Math.random() * 99999);
  element.parentNode.appendChild(clone);
  const height = document.getElementById(clone.id)?.offsetHeight;
  clone.remove();
  return height;
};

export const isIntersecting = (element, cb, rootMargin = "200px"): void => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        cb();
        observer.unobserve(element);
      }
    }),
      { root: null, rootMargin };
  });
  observer.observe(element);
};

export const cookieSet = (key, value, location, days) => {
  let expires = "",
    path = "";
  if (typeof days != "undefined" && days != "") {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toString();
  }
  if (typeof location != "undefined" && location != "") {
    path = "; path=" + location;
  }
  document.cookie = key + "=" + encodeURIComponent(value) + expires + path;
};

export const cookieGet = (key) => {
  const keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
  return keyValue ? decodeURIComponent(keyValue[2]) : null;
};
