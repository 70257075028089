class TablePressHandler {
  public tables = document.querySelectorAll(".tablepress");
  private lang = {
    emptyTable: "Brak danych",
    info: "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
    infoEmpty: "Pozycji 0 z 0 dostępnych",
    infoFiltered: "(filtrowanie spośród _MAX_ dostępnych pozycji)",
    infoPostFix: "",
    lengthMenu: "Pokaż _MENU_ pozycji",
    loadingRecords: "Wczytywanie...",
    processing: "Przetwarzanie...",
    search: "Szukaj:",
    zeroRecords: "Nie znaleziono pasujących pozycji",
    paginate: { first: "Pierwsza", previous: "Poprzednia", next: "Następna", last: "Ostatnia" },
    aria: {
      sortAscending: ": aktywuj, by posortować kolumnę rosnąco",
      sortDescending: ": aktywuj, by posortować kolumnę malejąco",
    },
    decimal: ",",
    thousands: " ",
  };

  private src = "/wp-content/plugins/tablepress/js/jquery.datatables.min.js";

  constructor() {
    if (this.tables.length > 0) {
      this.init();
    }
  }

  public init = async () => {
    const tablePressScript = document.querySelector("#tablepress-datatables-js");
    if (!tablePressScript) {
      await this.loadComponent(this.src);
    }

    (<any>window).jQuery(($) => {
      this.tables.forEach(({ id }) => {
        $(`#${id}`).DataTable({ language: this.lang, order: [], orderClasses: false, stripeClasses: ["even", "odd"], pagingType: "simple" });
      });
    });
  };

  loadComponent = (src) => {
    return new Promise((resolve, reject) => {
      const s = document.createElement("script") as HTMLScriptElement;
      s.src = src;
      s.type = "text/javascript";
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  };
}

export default TablePressHandler;
