import { isIntersecting } from "./../helpers";
import SectionAnimations from "./animations";

class Footer implements Section {
  public animations: SectionAnimations;

  constructor(private readonly rootElement: HTMLElement) {
    this.animations = new SectionAnimations(rootElement);

    isIntersecting(rootElement, () => {
      this.animations.start();
    });
  }
}

export default Footer;
